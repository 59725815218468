<template>
  <div class="breadCrumbBox">
    <div class="breadCrumb">
      <div class="item" v-for="(item, index) in breadCrumbList" :key="index" @click="handleJump(item)">
          <span :class="currentTitle == item.title ? 'themeColor' : 'cor-333333'">{{ item.title }}</span>
          <b class="iconfont" v-if="breadCrumbList.length - 1 !== index">{{ `\ue6aa` }}</b>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'newBreadCrumb',
  props:{},
  components:{},
  data() {
    return {
      breadCrumbList:[],
      currentTitle: '',
    }
  },
  created(){
    this.getBreadcrumb();
  },
  watch:{
    $route(to,from){
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb(){
      let arr = [{path: '/',meta:{title: '首页'}},...this.$route.matched]
      this.breadCrumbList = arr.filter(item=>item.meta && item.meta.title).map(v=>{
        return {
          title:v.meta.title,
          path:v.path
        }
      });
      this.currentTitle = this.$route?.meta?.title || ""
    },
    handleJump(item) {
      this.$router.push(item.path)
    }
  },
  mounted() {}
}
</script>
<style scoped lang="less">
@themeColor:#00C4C9;
.cor-333333 {
  color: #333333;
}
.themeColor {
  color: @themeColor;
}
.breadCrumbBox {
  font-size: 16px;
}
  .breadCrumb {
    display: flex;
    margin-top: 1.1em;
    .item {
      display: flex;
      align-items: center;
      font-size: 1em;
      span {
        margin-right:0.3125em;
        cursor: pointer;
        font-weight: bold;
      }
      b {
        margin-right: 0.3125em;
        font-size: 1.25em;
        position: relative;
        top: 0.0313em;
        // font-weight: 100;
      }
    }
  }
  // 移动端开始
@media screen and (max-width: 768px) {
  .breadCrumbBox {
    position: sticky;
    background-color: #fff;
    top: 55px;
    padding: 0 0 4px;
    z-index: 999;
  }
  .breadCrumb {
    display: flex;
    margin-top: 12px;
    .item {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        margin-right:4px;
        cursor: pointer;
        font-weight: bold;
      }
      b {
        margin-right: 4px;
        font-size: 18px;
        position: relative;
        top: 0.5px;
        font-weight: normal;
      }
    }
  }
}

// 移动端结束


// 平板开始
@media screen and (min-width: 768px) and (max-width: 992px) {
  .breadCrumbBox {
    font-size: 14px;
  }
}
// 平板结束

// 中等屏幕开始

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .breadCrumbBox {
    font-size: 18px;
  }
}

// 中等屏幕结束

// 大屏幕1200px - 1596px 开始
@media screen and (min-width: 1200px) and (max-width: 1596px) {
  .breadCrumbBox {
    font-size: 20px;
  }
}
// 大屏幕1200px - 1596px 结束


// 大屏幕1596px 开始
@media screen and (min-width: 1596px) {
  .breadCrumbBox {
    font-size: 24px;
  }
}
// 大屏幕1596px 结束
</style>